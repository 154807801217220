
var dvinci = function ( global ) {

  function init()
  {
    semanticUISetup();
    slicksliderSetup();
  }

  // Call jQuery ready() function
  $(document).ready(init);

  function semanticUISetup()
  {
    $('.tabular .item').tab();

    var btn_side_bar = $('.side-bar-toggle'),
        sidebar_activity_information = $('.sidebar-activity-information'),
        sidebar_activity_information_close = sidebar_activity_information.find('.close'),
        body = $('body');

    btn_side_bar.click(function(e) {

      e.preventDefault();

      sidebar_activity_information
        .sidebar({

          onVisible: function() {

            body.addClass('pushable');

            sidebar_activity_information_close.click(function(e) {

              e.preventDefault();
              sidebar_activity_information.sidebar('toggle');

            });

          },
          onHidden: function() {

            body.removeClass('pushable');

          }
        })
        .sidebar('setting', 'transition', 'overlay')
        .sidebar('toggle');

    });
  }

  function slicksliderSetup()
  {
    var slider = $('.slider');

    if(slider[0])
    {
      slider.slick({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        appendArrows: slider.parents('.slider-container').find('.slider-buttons'),
        prevArrow: "<a class='slick-arrow slick-prev' href='#'><i class='fa fa-chevron-left'></i></a>",
        nextArrow: "<a class='slick-arrow slick-next' href='#'><i class='fa fa-chevron-right'></i></a>",
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }
  }

}( window );
